/* routes */
export const INDEX_ROUTE = '/';
export const LOGIN_ROUTE = '/login';
export const REGISTER_ROUTE = '/register';
export const RESET_ROUTE = '/reset';
export const VIEW_ROUTE = '/view';

export const LEARN_ROUTE = '/learn';
export const PRICE_ROUTE = '/price';
export const BILLING_ROUTE = '/billing';

export const DASHBOARD_ROUTE = '/dashboard';
export const PORTFOLIO_ROUTE = '/portfolio';
export const EDITOR_ROUTE = '/editor';
export const PROFILE_ROUTE = '/profile';
export const FILE_ROUTE = '/file';

/* sort */
export const SORT_BY_ALPHABET = 'alphabet';
export const SORT_BY_UPDATE_TIME = 'update';
export const SORT_BY_CREATE_TIME = 'create';

export const DEFAULT_SORT = SORT_BY_UPDATE_TIME;

/* files types */
export const FILE_TYPE_SPINNER = 'spinner';
export const FILE_TYPE_PANORAMA = 'panorama';
export const FILE_TYPE_FOLDER = 'folder';

export const ALL_FILES_TYPES = [ FILE_TYPE_SPINNER, FILE_TYPE_PANORAMA, FILE_TYPE_FOLDER ];

/* other */
export const ROOT_FOLDER_NAME = 'All Projects';
