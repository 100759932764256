import { ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import {tryCatch} from '../helpers/tryCatch';

const { REACT_APP_API_URL } = process.env;

export const getEntityHash = ( id ) => tryCatch( () => id.split( '/' ).pop() );

const httpLink = createHttpLink( {
  uri: `${ REACT_APP_API_URL }/api/graphql`,
} );

const authLink = setContext( ( _, { headers } ) => {
  const token = localStorage.getItem( 'token' );

  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${ token }` : '',
    }
  }
} );

export const apolloAuthClient = new ApolloClient({
  link: authLink.concat( httpLink ),
  cache: new InMemoryCache(),
} );

export const apolloClient = new ApolloClient({
  link: httpLink,
  cache: new InMemoryCache(),
} );
